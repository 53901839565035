<template>
  <div
    id="avatar"
    class="avatar"
    :class="{ 'avatar--no-border': noBorder, 'avatar--square': square }"
    :style="{ width: `${width}px`, height: `${height}px` }"
  >
    <span v-if="initials && !isValidUrl && !isBase64" class="avatar__placeholder">
      {{ initials }}
    </span>
    <img v-else :src="src" :alt="alt" />
  </div>
</template>

<script setup lang="ts">
import type { ImgHTMLAttributes } from 'vue'
import { string } from 'zod'
import type { User } from '~/types/auth'

const props = defineProps<{
  src: ImgHTMLAttributes['src']
  alt: ImgHTMLAttributes['alt']
  width?: number | string
  height?: number | string
  user?: User
  noBorder?: boolean
  square?: boolean
}>()

const MAX_INITIALS = 3

const initials = computed(() => {
  if (!props.user?.name) return ''

  return props.user.name
    .split(' ')
    .map(n => n[0])
    .join('')
    .substring(0, MAX_INITIALS)
})

const isValidUrl = computed(() => string().url().safeParse(props.src).success)

const isBase64 = computed(() => Boolean(props.src?.startsWith('data:image')))
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid $black-100;
  background: $gold-primary-20;
  overflow: hidden;
  flex-shrink: 0;
  text-transform: uppercase;

  @include headline3();

  @media screen and (max-width: 1023px) {
    background-color: $gold-primary-50;
  }

  &--no-border {
    border: unset;
  }

  &--square {
    border-radius: 8px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
